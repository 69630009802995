const Swiper_Notice = ()=>{

    //Função que inicializa o Swiper
    function init_swiper(idSwiper){
        var swiper = new Swiper(`${idSwiper}`, {
            slidesPerView: 3,
            spaceBetween: 0,
            
        });
    }


    //Função principal
    function main(){
        init_swiper('.Swiper-Notices');
    }

    main();
}

export default Swiper_Notice;