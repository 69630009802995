
import Nav from "./componentes/nav.js";

import Swiper_Notice from "./componentes/swiper/swiper-notice.js";
import Select_config from "./componentes/selectConfig.js";

import Debug_text_equipe from "./componentes/debug_text_equipe.js";

import ModalEquipe from "./componentes/modal/modal_equipe.js";

import contatoForm from "./contatoForm.js"
import mostrarSenha from "./mostrarSenha.js"

import Translate from "./componentes/translate.js";

// PAGES
const pageHome = document.querySelector("#page__home");
const pageQuemSomos = document.querySelector("#page__quem_somos");
const pageServicos = document.querySelector("#page__servicos");

// ★ Pages Scopo
if (pageHome) { // ★ HOME
  document.body.classList.add("body__home");

  Swiper_Notice();
  Nav();
  
  
  Select_config('.select-lang');
  Translate();
 
}
else if(pageQuemSomos){ // ★ QUEM SOMOS
  document.body.classList.add("body__quem_somos");

  Nav();
  Select_config('.select-lang');
  Debug_text_equipe();
  ModalEquipe();
  Translate();
}
else if(pageServicos){ // ★ SERVIÇOS
  document.body.classList.add("body__servicos");

  Nav();
  Select_config('.select-lang');
  Translate();
}

document.addEventListener("DOMContentLoaded", () =>
  document.body.classList.add("dcl")
);

contatoForm()
mostrarSenha()
