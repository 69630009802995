const Debug_text_equipe = () =>{

    function addQuebraLinha(text){
        return(text.replace("\n", "<br /><br />"));
    }

    function main(){
        const cardsTexts = document.querySelectorAll(".card-equipe .main-card .box-text p");
        cardsTexts.forEach((text)=>{
            text.innerHTML = addQuebraLinha(text.innerHTML);
        })
    }

    document.addEventListener("DOMContentLoaded", ()=>{
        main();
    })
}

export default Debug_text_equipe;