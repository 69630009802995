const ModalEquipe = () =>{

    function handleButtonActive(classCardEquipe, classButton){
        return (document.querySelectorAll(`.${classCardEquipe} .${classButton}`));

    }

    function activeModal(idModal){
        const Modal = document.querySelector(`#${idModal}`);
        Modal.classList.add("active");
    }

    function closedModal(idModal){
        const Modal = document.querySelector(`#${idModal}`);
        Modal.classList.remove("active");
    }

    function transferenciadeinfo(idModal, classBodyModal, classCardEquipe, event){
        const boxInfoModal = document.querySelector(`#${idModal} .${classBodyModal} .content-2 .box-info`);
        const boxTextModal = document.querySelector(`#${idModal} .${classBodyModal} .content-2 .box-text`);
        const imageModal = document.querySelector(`#${idModal} .${classBodyModal} .content-1`);

        const idButton = event.attributes["data-id-button-equipe"].nodeValue;
        const boxInfo = document.querySelector(`.${classCardEquipe}[data-id-equipe='${idButton}'] .main-card .box-info`);
        const boxText = document.querySelector(`.${classCardEquipe}[data-id-equipe='${idButton}'] .main-card .box-text`);
        const image = document.querySelector(`.${classCardEquipe}[data-id-equipe='${idButton}'] .header-card`);

        boxInfoModal.innerHTML = boxInfo.innerHTML;
        boxTextModal.innerHTML = boxText.innerHTML;
        imageModal.innerHTML = image.innerHTML;
    }

    function main(){
        const idModal = "modal-equipe";
        const classBodyModal = "container-modal";
        const classButtonClosedModal = "button-closed-modal";

        const classCardEquipe = "card-equipe";
        const classButtonActiveModal = "button-add";

        const AllButtonsActive = handleButtonActive(classCardEquipe, classButtonActiveModal);
        const ButtonClosed = document.querySelector(`#${idModal} .${classButtonClosedModal}`);

        AllButtonsActive.forEach((button)=>{
            button.addEventListener("click", (event)=>{
                activeModal(idModal);
                transferenciadeinfo(idModal, classBodyModal, classCardEquipe, event.target);
            });
        });

        ButtonClosed.addEventListener("click", ()=>{
            closedModal(idModal);
        });

        const Modal = document.querySelector(`#${idModal}`);
        Modal.addEventListener("dblclick", ()=>{
            closedModal(idModal);
        });



    }

    document.addEventListener("DOMContentLoaded", ()=>{
        main();
    });
}

export default ModalEquipe;