const Nav = () =>{

    function addFixedNav(idNav, action){
        const Nav = document.querySelector(idNav);
        if(Nav){
            if(action === 'remove'){
                Nav.classList.remove('fixed');
            }
            else if(action === 'add'){
                Nav.classList.add('fixed');
            }
        }
    }

    function chackNavItens(idNavItens, action){
        const NavItens = document.querySelector(idNavItens);
        if(NavItens){
            if(action === 'remove'){
                NavItens.classList.remove('active');
            }
            else if(action === 'add'){
                NavItens.classList.add('active');
            }
        }
    }


    function event_scroll(idNav){
        document.addEventListener("scroll", ()=>{
            const positionY = window.scrollY;
            if(positionY > 72){
                addFixedNav(idNav, "add");
            }
            else{
                addFixedNav(idNav, "remove");
            }
        });
    }


    function main(){
        const idNav = "#nav-principal";
        const idNavItens = "#itens-nav-principal";
        const idButtonNav = "#button-nav-principal";

        const ButtonNav = document.querySelector(idButtonNav);
        if(ButtonNav){
            ButtonNav.addEventListener("click", (e)=>{
                if(e.target.classList.contains('active')){
                    
                    e.target.classList.remove('active');

                    chackNavItens(idNavItens, "remove");
                }
                else{

                    e.target.classList.add('active');

                    chackNavItens(idNavItens, "add");
                }
            }, false);
        }

        event_scroll(idNav);
        
    }

    main();
}

export default Nav;